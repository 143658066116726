import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  reviewDetail: null,
  socialMedia: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getSocialMediaReview.success, (state, { payload }) => {
        state.socialMedia = payload;
      })
      .addCase(actions.getReviewDetail.success, (state, { payload }) => {
        state.reviewDetail = payload;
      });
  },
});

const reviewServiceReducer = Slice.reducer;
export default reviewServiceReducer;
