import reviewSelectors from 'features/review/services/selectors';
import { ISocialMediaItem } from 'features/review/services/types/social';
import React from 'react';
import styled from 'styled-components';
import icon_facebook from './icon-facebook.png';
import icon_google from './icon-google.png';
import icon_instagram from './icon-instagram.png';
import icon_yelp from './icon-yelp.png';
import { get } from 'lodash';

const SOCIAL_INFO = {
  GOOGLE: { id: 'google', image: icon_google, label: 'Google Review' },
  YELP: { id: 'yelp', image: icon_yelp, label: 'Yelp Review' },
  INSTAGRAM: { id: 'instagram', image: icon_instagram, label: 'Instagram Review' },
  FACEBOOK: { id: 'facebook', image: icon_facebook, label: 'Facebook Review' },
};

type ISocialMediaUI = {
  id: string;
  image: any;
  label: string;
} & ISocialMediaItem;

type Props = {
  chooseSocial: (o: ISocialMediaItem) => void;
};
const Socials = ({ chooseSocial }: Props) => {
  const socialData = reviewSelectors.socialMedia();
  const socials = React.useMemo(() => {
    const data: ISocialMediaUI[] = [];
    socialData.map((o) => {
      const localData = get(SOCIAL_INFO, [o.channelName], null);
      if (!localData) return;
      data.push({
        ...o,
        ...localData,
      });
    });
    return data;
  }, [socialData]);
  return (
    <SocialContainer>
      {socials?.map(o => (
        <SocialStyled key={o.id} onClick={() => chooseSocial(o)}>
          <img className='icon' src={o.image} />
          <span className="social-label">{o.label}</span>
        </SocialStyled>
      ))}
    </SocialContainer>
  );
};


export default Socials;


const SocialContainer = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-top: 1.5rem;
`;

const SocialStyled = styled.div`
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 2px solid rgba(201, 205, 212, 0.50);
    background: #FFF;
    box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15);
    .icon {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
    } 
    .social-label {
      color: #566A7F;
      text-align: center;
      font-family: Poppins;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
`;
