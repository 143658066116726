import React, { useEffect, useRef, useState } from 'react';
import { Client, Message, Subscription } from 'stompjs';
import storage from 'utils/sessionStorage';
import { initSocket } from 'utils/socket';

const getTopic = (topic: string) => {
  return `${topic}/${storage.shopId.get()}`;
};

type SocketContextProps = {
  socket: Client | null,
  connect: (shop_id: string) => void;
  unsubscribeAll: () => void;
};
export const SocketContext = React.createContext<SocketContextProps>({
  socket: null,
  connect: () => undefined,
  unsubscribeAll: () => undefined,
});

export const useSocketContext = () => React.useContext(SocketContext);

const useInitSocket = (): SocketContextProps => {
  const [socket, setSocket] = useState<Client | null>(null);

  const unSubArrays = useRef<Subscription[]>([]);
  const unSubPassDataArrays = useRef<Subscription[]>([]);
  const waitingSubscribeArrays = useRef<{
    topic: string, listeningData: (message: Message) => void, disableDeviceId?: boolean;
  }[]>([]);

  const callBackReConnectSuccess = (_socket: Client, shopId: string) => {
    if(shopId) return;
    if (!_socket) return;
    if (waitingSubscribeArrays.current.length) {
      waitingSubscribeArrays.current.map(waitItem => {
        unSubPassDataArrays.current.push(
          _socket.subscribe(getTopic(waitItem.topic), waitItem.listeningData)
        );
      });
      waitingSubscribeArrays.current = [];
    }
  };

  const connect = (shopId: string) => {
    if (socket) {
      callBackReConnectSuccess(socket, shopId);
      return;
    }
    const _socket = initSocket();
    setSocket(_socket);
    _socket.connect({}, () => callBackReConnectSuccess(_socket, shopId));
  };

  useEffect(() => {
    return () => socket?.disconnect(() => undefined);
  }, []);

  const unsubscribeAll = () => {
    unSubArrays.current.map(o => o.unsubscribe());
    unSubArrays.current = [];
  };

  return ({
    socket,
    connect,
    unsubscribeAll,
  });
};

export default useInitSocket;
