
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { IReviewDetailResData } from './types/review';
import shopActions from 'services/shop/actions';
import { ISocialMediaItem } from './types/social';

const getReviewDetail: ISagaFunc<{ shopId: string, reviewId: string }> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<IReviewDetailResData> = yield call(apis.getReviewDetail, payload.shopId, payload.reviewId);
    if (res.data.payload) {
      yield put(actions.getReviewDetail.success(res.data.payload));
    }
  } catch (error) {
  } finally {
    yield setLoading(PATH_LOADING.getReviewDetail, false);
  }
};

const getSocialMediaReview: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<ISocialMediaItem[]> = yield call(apis.getSocialMediaReview, payload);
    if (res.data.payload) {
      yield put(actions.getSocialMediaReview.success(res.data.payload));
    }
  } catch (error) { }
};

const initReviewDetail: ISagaFunc<{ shopId: string, reviewId: string }> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getReviewDetail, true);
  yield delay(200);
  yield all([
    put(actions.getReviewDetail.fetch(payload)),
    put(actions.getSocialMediaReview.fetch(payload.shopId)),
    put(shopActions.getShopInfo.fetch(payload.shopId)),
  ]);
};

export default function* reviewServiceSagas() {
  yield takeLatest(actions.getReviewDetail.fetch, getReviewDetail);
  yield takeLatest(actions.initReviewDetail, initReviewDetail);
  yield takeLatest(actions.getSocialMediaReview.fetch, getSocialMediaReview);
}
