import React from 'react';
import styled from 'styled-components';
import top_icon from './top-icon.svg';
import divider_bg from './divider-bg.png';
import Text from 'components/Text';
import { QRCode } from 'antd';
import { formatCurrency } from 'utils/formatCurrency';

export type ICouponData = {
  type: string;
  value: number;
  name: string;
  subTitle: string;
  code: string;
  description: string;
  validDate: string;
  urlImage: string;
  endTime: string;
};


type Props = {
  couponData?: ICouponData | null;
};
const Coupon = ({ couponData }: Props) => {
  if (!couponData) return null;
  return (
    <ContainerBox>
      <Text className="sub_title" mb={1}>
        Leave a review on our social website and <br />get coupon up to {couponData.type === 'PERCENT' ? `${couponData.value}%` : formatCurrency(couponData.value)} off
      </Text>

      {couponData.urlImage ? <div className='coupon-image' style={{ backgroundImage: `url(${couponData.urlImage})` }} /> : (
        <CouponStyled>
          <div className="circle" style={{ left: -25 }} />
          <div className="content">
            <div className="coupon-info">
              <div className="coupon-money">
                {couponData.type !== 'PERCENT' ? (
                  <div className="coupon-money-top">
                    <span className="dollar">$</span>
                    <span className="value">{couponData.value}</span>
                    <span className="suffix">00</span>
                  </div>
                ) : (
                  <div className="coupon-percent-top">
                    <span className="percent">{couponData.value}</span>
                    <span className="percent-suffix">%</span>
                  </div>
                )}
                <div className={`coupon-money-bottom ${couponData.type === 'PERCENT' ? 'percent' : ''}`}>
                  <span>OFF</span>
                </div>
              </div>
              <div className="coupon-divider"></div>
              <div className="coupon-name">
                <img className='top-icon' src={top_icon} alt="top_icon" />
                <span className="name">{couponData.name}</span>
                <span className="sub-name">{couponData.subTitle}</span>
              </div>
              <div className="coupon-QR">
                <div className="coupon-QR-image">
                  <QRCode color='#fff' bordered={false} size={70} value={couponData.code} />
                </div>
                <div className="coupon-QR-value">{couponData.code}</div>
              </div>
            </div>
          </div>
          <div className="coupon-note">
            <span className="coupon-disc">
              {couponData.description}
            </span>
            <div className="valid-box" style={couponData.endTime ? {} : { width: '3rem' }}>
              {
                couponData?.endTime ? <>
                  <span className="normal">VALID DATE</span>
                  <span className="value">{couponData.validDate}</span>
                </> : <>
                  <span className="value" >Expire</span>
                  <span className="value">Date</span>
                </>
              }

            </div>
          </div>
          <div className="circle" style={{ right: -25 }} />
        </CouponStyled>
      )}
    </ContainerBox>
  );
};

export default Coupon;

const ContainerBox = styled.div`
  .title_page {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 8px;
  }
  .sub_title {
    color: #566A7F;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .coupon-image {
    width: 100%;
    height: 152.646px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.30);
    background-size: cover;
    background-repeat: no-repeat;
  }
`;


const CouponStyled = styled.div`
  width: 100%;
  background: #1A0F00;
  border-radius: 16px;
  position: relative;
  .circle {
    position: absolute;
    top: 60px;
    background: #fff;
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
  padding: 12px 0;
  .content {
    margin-left: 26px;
    margin-right: 30px;
    .coupon-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      .coupon-money {
        display: flex;
        padding: 6px 0;
        flex-direction: column;
        .coupon-money-top {
          display: flex;
          align-items: flex-start;
          height: 40px;
        }
        .coupon-percent-top {
          display: flex;
          align-items: flex-start;
          height: 40px;
          span.percent {
            color: #FFF;
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          span.percent-suffix {
            color: #FFF;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        span.dollar {
          color: #FFF;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.244px; /* 96.221% */
        }
        span.value {
          color: #FFF;
          font-family: Archivo;
          font-size: 45.571px;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          transform: translateY(-5px);
        }
        span.suffix {
          color: #FFF;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.636px; /* 78.18% */
          letter-spacing: -1.2px;
          text-decoration-line: underline;
        }

        .coupon-money-bottom {
          padding-left: 10px;
          span {
            color: #fff;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          &.percent {
            padding-left: 5px;
          }
        }
      }

      .coupon-divider {
        background: url(${divider_bg});
        width: 3.5px;
        align-self: stretch;
        background-size: contain;
        margin-left: 5px;
        margin-right: 10px;
        background-repeat: repeat-y;
      }


      .coupon-name {
        display: flex;
         flex-direction: column;
         gap: 4px;
         transform: translateY(-5px);
         flex: 1;
         .top-icon {
          width: 22px;
         }
         span.name {
          color: #FFF;
          font-family: Archivo;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
         }
         span.sub-name {
          color: #FFF;
          font-family: Archivo;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
         }
      }


      .coupon-QR {
        .coupon-QR-image {
          border: 1px solid #FFF;
          border-radius: 5px;
          margin-bottom: 4px;
          .ant-qrcode {
            padding: 0;
            margin: 0;
            width: 55px !important;
            height: 54px !important;
            overflow: unset;
          }
        }

        .coupon-QR-value {
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

      }

    }
  }
  
  .coupon-note {
    padding: 4px;
    background: rgba(255, 255, 255, 0.30);
    margin: 0 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .coupon-disc {
      flex: 1;
      color: #FFF;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 10px;
      white-space: break-spaces;
    }

    .valid-box {
      background: #190F00;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      padding: 4px;

      span.normal {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 7px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      span.value {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;
