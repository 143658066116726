import React from 'react';
import styled from 'styled-components';
import headerBg from 'assets/header-bg.jpeg';
import shopSelectors from 'services/shop/selectors';
import { formatPhone } from 'utils/formatPhone';

type IHeaderProps = any;
const ShopInfo: React.FC<IHeaderProps> = () => {
  const shopInfo = shopSelectors.shopInfo();
  return (
    <HeaderStyled>
      <HeaderContentContainer>
        <div>
          <HeaderTitle className="header-title">
            <strong>{shopInfo?.companyName || ''}</strong>
          </HeaderTitle>
          <HeaderAddress className="header-address-title">
            {shopInfo?.address} <br /> {formatPhone(shopInfo?.phone || '')}
          </HeaderAddress>
        </div>
      </HeaderContentContainer>
    </HeaderStyled>
  );
};

export default ShopInfo;
type HeaderStyledProps = {};
const HeaderStyled = styled.div<HeaderStyledProps>`
  width: 100%;
  height: 150px;
  background: url(${headerBg}) no-repeat center center;
  background-size: cover;
  background-color: #00000066;
  position: relative;
  @media screen and (min-width: 768px) {
    height: 150px;
    .header-title {
      font-size: 2rem;
      line-height: 3rem;
    }
    .header-address-title {
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }
`;

const HeaderContentContainer = styled.div`
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderTitle = styled.div`
  color: #ffffff;
  font-family: Russo One;
  font-size: 22px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
`;

const HeaderAddress = styled.div`
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`;
