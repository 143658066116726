import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
type MyState = RootState['shop'];

const getCurrentState = (state: RootState): MyState => state['shop'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));
const loading = {
  staff: () => uiSelector.getLoading(PATH_LOADING.staff),
  services: () => uiSelector.getLoading(PATH_LOADING.services),
  getData: () => uiSelector.getLoading(PATH_LOADING.getData),
};
const shopSelectors = {
  staff: () => selector('staffs') as MyState['staffs'],
  customers: () => selector('customers') as MyState['customers'],
  categories: () => selector('categories') as MyState['categories'],
  allServices: () => selector('allServices') as MyState['allServices'],
  shopInfo: () => selector('shopInfo') as MyState['shopInfo'],
  loading,
};

export default shopSelectors;
