import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { createAction } from '@reduxjs/toolkit';

const initReviewDetail = createAction<{ shopId: string, reviewId: string }>(PREFIX_ACTIONS + 'initReviewDetail');

const getReviewDetail = createAsyncAction<{ shopId: string, reviewId: string }>(PREFIX_ACTIONS + 'getReviewDetail');

const getSocialMediaReview = createAsyncAction<string>(PREFIX_ACTIONS + 'getSocialMediaReview');

const reviewActions = {
  initReviewDetail,
  getReviewDetail,
  getSocialMediaReview,
};

export default reviewActions;

