import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IServiceUI } from './types/service';

const initialState: IState = {
  staffs: [],
  categories: [],
  allServices: [],
  customers: [],
  shopInfo: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getShopInfo.success, (state, { payload }) => {
        state.shopInfo = payload;
      })
      .addCase(actions.data.category.success, (state, { payload }) => {
        state.categories = payload;
        const services: IServiceUI[] = [];
        state.categories.map(cate => {
          cate.services.map(s => {
            services.push({
              ...s,
              cateId: cate.id,
            });
          });
        });
        state.allServices = services;
      })
      .addCase(actions.data.staff.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.data.customer.success, (state, { payload }) => {
        state.customers = payload;
      })
      ;
  },
});

const shopServiceReducer = Slice.reducer;
export default shopServiceReducer;
