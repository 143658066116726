
import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import storage from 'utils/sessionStorage';
import actions from './actions';
import apis from './apis';
import { ICategoryResItem, IServiceResItem, IStaffResItem } from './types/response';
import { ICategoryUI } from './types/category';
import { IServiceUI } from './types/service';
import { IStaffUI } from './types/staff';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import { IResponseDataBody } from 'services/response';
import { IShopInfo } from './types/shop';

const getCategories = function* (shopId: string) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.services, result: true }));
  try {
    const { cate, ser } = yield all({ cate: call(apis.getCategories, shopId), ser: call(apis.getServices, shopId) });

    const categoriesRes: ICategoryResItem[] = cate?.data?.payload;
    const servicesRes: IServiceResItem[] = ser?.data?.payload;
    if (categoriesRes && servicesRes) {
      const data: ICategoryUI[] = categoriesRes.map((o) => ({
        id: o.id?.toString(),
        name: o.categoryName,
        services: servicesRes.filter(s => s.catId === o.id).map(s => ({
          cateId: o.id?.toString(),
          id: s.id?.toString(),
          image: s.urlImage,
          name: s.serviceName,
          duration: s.duration,
          price: s.price,
        }) as IServiceUI),
      }));
      yield put(actions.data.category.success(data));
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.services, result: false }));
  }
};

const getStaffs = function* (shopId: string) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.staff, result: true }));
  try {
    const res: AxiosResponse<{ payload: IStaffResItem[] }> = yield call(apis.getStaffs, shopId);
    const staffRes = res?.data?.payload;
    if (staffRes) {
      const data: IStaffUI[] = staffRes.map(o => ({
        avatar: o.urlImage,
        extraData: o,
        id: o.id?.toString(),
        name: o.firstName,
      }));
      yield put(actions.data.staff.success(data));
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.staff, result: false }));
    yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: false }));
    yield put(actions.initDone());
  }
};

const getShopInfo: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<IShopInfo> = yield call(apis.getShopInfo, payload);
    if (res.data.payload) {
      yield put(actions.getShopInfo.success(res.data.payload));
    }
  } catch (error) { }
};

const initShopData: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  yield storage.shopId.set(payload);
  yield delay(200);

  yield all([
    getCategories(payload),
    getStaffs(payload),
  ]);
};


export default function* shopServiceSagas() {
  yield takeLatest(actions.init, initShopData);
  yield takeLatest(actions.getShopInfo.fetch, getShopInfo);
}
