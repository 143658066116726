import LoadingPage from 'components/LoadingPage';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useSocketContext } from 'services/useSocket';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';

function ShopWrapperLayout() {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  const socket = useSocketContext();
  const loading = shopSelectors.loading.getData();
  const initConnectSocket = debounce(() => socket.connect(shop_id), 100);
  useEffect(() => {
    dispatch(shopActions.init(shop_id || storage.shopId.get()));
    initConnectSocket();
    return () => {
      socket.unsubscribeAll();
    };
  }, []);

  if (loading) return <LoadingPage />;

  return (
    <><Outlet /></>
  );
}

export default ShopWrapperLayout;
