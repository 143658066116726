import reviewServiceReducer from 'features/review/services/reducers';
import uiServiceReducer from 'services/UI/reducer';
import shopServiceReducer from 'services/shop/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  shop: shopServiceReducer,
  review: reviewServiceReducer,
};

export default rootReducer;
