import numeral from 'numeral';

export const formatCurrency = (
  value: string | number | null | undefined,
  showMinus?: boolean,
  showMoney = true
) => {
  const formatString = showMoney ? '$0,0.00' : '0,0.00';
  
  if (showMinus && Number(value) < 0) {
    return `- ${numeral(value || 0).format(formatString)}`;
  }
  
  return numeral(value || 0).format(formatString);
};
