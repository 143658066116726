import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const init = createAction<string>(PREFIX_ACTIONS + 'getInitShopData');
const initDone = createAction(PREFIX_ACTIONS + 'initDone');
const getShopInfo = createAsyncAction<string>(PREFIX_ACTIONS + 'getShopInfo');
const data = {
  staff: createAsyncAction(PREFIX_ACTIONS + 'getStaffs'),
  category: createAsyncAction(PREFIX_ACTIONS + 'category'),
  customer: createAsyncAction(PREFIX_ACTIONS + 'customer'),
};

const shopActions = {
  data,
  init,
  initDone,
  getShopInfo,
};

export default shopActions;

