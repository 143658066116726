import React, { CSSProperties, useMemo } from 'react';
import { css, styled } from 'styled-components';
import { ISpacing, generateCssSpacing } from 'utils/spacing';
const TEXT_VARIANT_TYPES = {
  BODY_1: 'BODY_1',
  BODY_2: 'BODY_2',
  BODY_BOLD: 'BODY_BOLD',
  TITLE: 'TITLE',
  SUB_TITLE: 'SUB_TITLE',
  TITLE_SECTION: 'TITLE_SECTION',
  TEXT_TIME: 'TEXT_TIME',
  HEAD_NAME: 'HEAD_NAME',
  TEXT_LABEL: 'TEXT_LABEL',
  SUB_TEXT_SECTION: 'SUB_TEXT_SECTION',
  LINK: 'LINK',
  ERROR_TEXT_SUPPORT: 'ERROR_TEXT_SUPPORT',
  TITLE_BS: 'TITLE_BS',
};

export interface ITextProps extends ISpacing {
  id?: string;
  inline?: boolean;
  variant?: keyof typeof TEXT_VARIANT_TYPES;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  textDecorate?: string;
  textAlign?: CSSProperties['textAlign'];
  whiteSpace?: CSSProperties['whiteSpace'];
  margin?: CSSProperties['margin'];
  style?: CSSProperties;
  wrapWidth?: string;
  textTransform?: CSSProperties['textTransform'];
  printMode?: boolean;
  flexUI?: boolean;
}
const Text: React.FC<ITextProps> = ({
  id,
  color,
  variant = 'BODY_1',
  children = null,
  className,
  textDecorate,
  textAlign,
  wrapWidth,
  printMode = false,
  flexUI,
  ...props
}) => {
  const styles: React.CSSProperties = useMemo(() => {
    const flexStyles: React.CSSProperties = flexUI ? {
      display: 'flex',
      alignItems: 'center',
    } : {};
    return {
      ...generateCssSpacing(props),
      width: wrapWidth,
      textTransform: props.textTransform,
      whiteSpace: props.whiteSpace,
      color: color,
      ...flexStyles,
      ...(props.style ?? {}),
    };
  }, [props]);

  return (
    <TextStyled
      id={id}
      textAlign={textAlign}
      textDecorate={textDecorate}
      color={color}
      className={`${variant} ${className ?? ''} ${printMode ? 'print-text' : ''
        }`}
      style={styles}
    >
      {children}
    </TextStyled>
  );
};

export default Text;

const TEXT_VARIANT_CSS = {
  [TEXT_VARIANT_TYPES.BODY_1]: `
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 137.5%;
  `,
  [TEXT_VARIANT_TYPES.BODY_2]: `
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  `,
  [TEXT_VARIANT_TYPES.TITLE]: `
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%;
  `,
  [TEXT_VARIANT_TYPES.SUB_TITLE]: `
    color: #686868;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 137.5%;
  `,
  [TEXT_VARIANT_TYPES.TITLE_SECTION]: `
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%;
  `,
  [TEXT_VARIANT_TYPES.TEXT_TIME]: `
    color: #686868;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 137.5%; /* 22px */
  `,
  [TEXT_VARIANT_TYPES.BODY_BOLD]: `
    color: #686868;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.25px;
    `,
  [TEXT_VARIANT_TYPES.HEAD_NAME]: `
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
  `,
  [TEXT_VARIANT_TYPES.TEXT_LABEL]: `
    color: #686868;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  `,
  [TEXT_VARIANT_TYPES.SUB_TEXT_SECTION]: `
    color: #686868;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 137.5%; /* 16.5px */
  `,
  [TEXT_VARIANT_TYPES.LINK]: `
    color: #1888B8;
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.ERROR_TEXT_SUPPORT]: `
    color: #F00;
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 137.5%; /* 16.5px */
  `,
  [TEXT_VARIANT_TYPES.TITLE_BS]: `
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%; /* 24.75px */
  `,
};

type TextStyledProps = {
  color?: ITextProps['color'];
  textDecorate?: string;
  textAlign?: ITextProps['textAlign'];
  margin?: ITextProps['margin'];
};

const textStyledCSS = css<TextStyledProps>`
  text-align: ${({ textAlign }) => textAlign || 'unset'};
  color: ${({ color }) => (color ? color : '#1D2129')};
  text-decoration: ${({ textDecorate }) => textDecorate || 'none'};
  margin: ${({ margin }) => margin || '0'};
`;

const TextStyled = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['textDecorate', 'textAlign', 'color'].includes(prop),
}) <TextStyledProps>`
  ${textStyledCSS}
  ${Object.entries(TEXT_VARIANT_CSS).map(
  ([key, css]) => `
      &.${key} {
        ${css}
      }
    `
)}
  &.print-text {
    font-family: "Roboto", serif !important;
    color: black !important;
    padding: 1px 0px;
  }

  font-family: Poppins;
`;
