import { signal } from '@preact/signals-react';
import LoadingPage from './LoadingPage';

export const loadingSignal = signal(false);

const LoadingCalendar = () => {
  if (!loadingSignal.value) return null;
  return <LoadingPage />;
};

export default LoadingCalendar;
