import { Spin } from 'antd';
import { styled } from 'styled-components';

const LoadingPage = () => {
  return (
    <LoadingStyled ><Spin spinning /></LoadingStyled>
  );
};

export default LoadingPage;

const LoadingStyled = styled.div`
position: fixed;
inset: 0;
z-index: 9999;
display: flex;
align-items: center;
background: rgba(255, 255, 255, 1);
justify-content: center;
`;
