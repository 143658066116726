import { AxiosResponse } from 'axios';
import fetch from 'services/request';
import { ICategoryResItem, IServiceResItem } from './types/response';

// const baseURL = 'api/v1/:merchant_code/demoData';

export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
  // return fetch({
  //   method: 'get',
  //   url: `${baseURL}/getDemoData`,
  //   params: params as any
  // });
};


const getCategories = (shopId: string): Promise<AxiosResponse<{ payload: ICategoryResItem[] }>> => {
  return fetch({
    url: 'api/v1/service/category/list',
    shopId,
  });
};

const getServices = (shopId: string): Promise<AxiosResponse<{ payload: IServiceResItem[] }>> => {
  return fetch({
    url: 'api/v1/service/list',
    shopId,
  });
};
const getStaffs = (shopId: string) => {
  return fetch({
    url: 'api/v1/staff/list',
    shopId,
  });
};

const getShopInfo = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/detail',
    shopId,
  });
};

const shopApis = {
  getStaffs,
  getCategories,
  getServices,
  getShopInfo,
};

export default shopApis;
