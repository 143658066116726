
export const NAME_REDUCER = 'review';

export const PREFIX_ACTIONS = 'review_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getReviewDetail: `loading.${NAME_REDUCER}.getReviewDetail`,
};
