import fetch from 'services/request';

const getReviewDetail = (shopId: string, reviewId: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/reviews/detail/${reviewId}`,
    shopId,
  });
};

const getSocialMediaReview = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/reviews/social-media',
    shopId,
  });
};

const updateChannelReview = (shopId: string, reviewId: string, channel: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/reviews/update-channel-review',
    shopId,
    body: { channel, reviewId }
  });
};


const reviewApis = {
  getReviewDetail,
  getSocialMediaReview,
  updateChannelReview,
};

export default reviewApis;
