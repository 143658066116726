import LoadingPage from 'components/LoadingPage';
import ShopInfo from 'components/ShopInfo';
import NotFoundPage from 'features/NotFound';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import reviewActions from './services/actions';
import reviewSelectors from './services/selectors';
import Coupon, { ICouponData } from './components/Coupon';
import moment from 'moment';
import styled from 'styled-components';
import Socials from './components/Socials';
import { ISocialMediaItem } from './services/types/social';
import { IResponseDataBody } from 'services/response';
import reviewApis from './services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';

const ReviewDetailPage = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '', review_id = '' } = useParams();
  const reviewDetail = reviewSelectors.getReviewDetailData();
  const socialMedia = reviewSelectors.socialMedia();
  const reviewLoading = reviewSelectors.getDetailLoading();
  const setLoadingPage = useSetLoadingPage();

  useEffect(() => {
    dispatch(reviewActions.initReviewDetail({ shopId: shop_id, reviewId: review_id }));
  }, []);

  const handleChooseSocial = async (o: ISocialMediaItem) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await reviewApis.updateChannelReview(shop_id, review_id, o.channelName);
      if (res.data.payload) {
        window.open(o.channelUrl, '_blank');
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const couponData = React.useMemo(() => {
    const coupon = reviewDetail?.coupon;
    if (!coupon) return null;
    const days = moment().diff(moment(coupon.endTime), 'days');
    return ({
      code: coupon.couponCode,
      name: coupon.couponName,
      subTitle: coupon.title,
      description: coupon.description,
      type: coupon.discountType === 0 ? 'PERCENT' : 'MONEY',
      value: coupon.discountValue,
      endTime: coupon.endTime ? `${days} Days` : '',
      validDate: coupon.endTime ? `${days} Days` : '',
      urlImage: coupon.urlImage,
    }) as ICouponData;
  }, [reviewDetail]);

  if (reviewLoading) return <LoadingPage />;

  if (!reviewDetail) return <NotFoundPage />;
  console.log('reviewDetail', reviewDetail);
  console.log('socialMedia', socialMedia);

  return (
    <div>
      <ShopInfo />
      <ContainerStyled>
        <Coupon couponData={couponData} />
        <Socials chooseSocial={handleChooseSocial} />
      </ContainerStyled>
    </div>
  );
};

export default ReviewDetailPage;

const ContainerStyled = styled.div`
  padding: 16px;
`;

