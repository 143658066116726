import ShopWrapperLayout from 'ShopWrapperLayout';
import { ConfigProvider } from 'antd';
import LoadingCalendar from 'components/Loading';
import NotFoundPage from 'features/NotFound';
import ReviewDetailPage from 'features/review/detail.page';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingPage from 'services/UI/LoadingPage';
import store from 'store';

const AppLayout: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/:shop_id" element={<ShopWrapperLayout />}></Route>
        <Route path='/:shop_id/review/:review_id' element={<ReviewDetailPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <LoadingCalendar />
      <LoadingPage />
    </>
  );
};

const App: React.FC = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#1888B8',
        colorPrimaryBg: '#1888B8',
        colorPrimaryActive: '#0C719C',
        colorText: '#86909C',
        colorTextSecondary: '#767676',
        colorBorder: '#86909C',
        colorBorderSecondary: '#CECECE',
        colorFill: '#E5E6EB',
        colorFillSecondary: '#E5E5E5',
        colorFillTertiary: '#F6F7FC',
        colorBgLayout: '#E5E5E5',
        colorBgSpotlight: '#1D2129',
        colorBgMask: 'rgba(0, 0, 0, 0.2)',
        colorTextBase: '#86909C',
        colorBgBase: '#F6F7FC',
        colorWarning: '#ff7d00',
        colorError: '#FF0000',
        colorInfo: '#6fabb6',
        fontSize: 16,
        sizeStep: 4,
        borderRadius: 5,
        borderRadiusXS: 2,
        wireframe: false,
      },
    }}
  >
    <Provider store={store}>
      <AppLayout />
      <ToastContainer />
    </Provider>
  </ConfigProvider>
);

export default App;
