import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { IReviewDetailResData } from './types/review';

type MyState = RootState['review'];
const getCurrentState = (state: RootState): MyState => state['review'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getReviewDetailData = () => selector('reviewDetail') as IReviewDetailResData;

const getDetailLoading = () => uiSelector.getLoading(PATH_LOADING.getReviewDetail) as boolean;

const socialMedia = () => selector('socialMedia') as MyState['socialMedia'];

const reviewSelectors = {
  getReviewDetailData,
  getDetailLoading,
  socialMedia,
};
export default reviewSelectors;
